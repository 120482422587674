
<!--------------------------------------------
| TABELA DE LISTAGEM DE DOADORES
|-------------------------------------------->

<template>
    <div>
      <v-card class="rounded-lg" elevation="0">
        <v-tabs fixed-tabs background-color="green" dark>
          <v-tabs-slider color="#086318"></v-tabs-slider>
            <v-tab>SOROLOGIA</v-tab>
 
            <v-tab-item>
              <v-card-text>
                <v-form @submit.prevent="search(donorSearch)" ref="searchDonor">
                    <!-- <v-row>
                        <v-col lg="12" cols="12">
                            <v-alert outlined type="success" text color="#edb24c">
                            <strong>Seja Bem Vindo, {{ $store.state.authUser.name }}!</strong>
                            </v-alert>
                        </v-col>
                    </v-row> -->
                  <v-container>
                    <v-row>
                      <v-col class="d-flex" cols="12" sm="12">
                        <v-text-field
                        color="#086318" 
                        label="Pesquisar" 
                        placeholder="Nome completo" 
                        outlined 
                        v-model="donorSearch.name"
                        dense></v-text-field>
                      </v-col>
                    </v-row>

                    <p class="space-personalized"></p>
                    <v-row>
                      <div class="space"></div>
                      
                      <v-btn class="rounded-lg"
                        elevation="0" 
                        color="green" 
                        type="submit">
                        <v-icon class="mr-2" color="#FFFFFF">mdi-account-search</v-icon>
                        <span class="textBtn"> Pesquisar </span>
                      </v-btn>

                      <div class="space"></div>
                      
                      <v-btn class="rounded-lg"
                        elevation="0" 
                        color="grey" 
                        @click="clear()">
                          <span class="textBtn"> Limpar </span>
                      </v-btn>

                      <div class="space"></div>

                      <v-btn class="rounded-lg"
                        elevation="0" 
                        color="green" 
                        @click="$router.push('/cadastrar-sorologia')">

                          <v-icon
                          class="mr-2" 
                          color="#FFFFFF">mdi-needle</v-icon>

                          <span class="textBtn"> Cadastrar Sorologia </span>
                      </v-btn>
                      
                    </v-row>
                  </v-container>
                </v-form>
              </v-card-text>
  
              <!-- Início da Tabela -->
              <v-data-table
                :headers="headers"
                :items="donor"
                :footer-props="{'items-per-page-text': 'Doador por Página',}"
                :page="page"
                :loading="loading">
        
                <template v-slot:[`item.actions`]="{ item }" :resource="item">
                  <v-icon 
                    size="22"
                    class="icon"
                    color="#3fa118" 
                    align-item="center"
                    @click="$router.push({path: '/editar-sorologia', query:{item:item}})">mdi-pencil 
                  </v-icon>
                </template>
                
                
                <template v-slot:[`item.cpf`]="{ item }">
                  {{ item.cpf | VMask("###.###.###-##") }}
                </template>
        
              </v-data-table>
           </v-tab-item>
      </v-tabs>
  </v-card>
  </div>
  </template>
  
  <script>
  import axios from "@/axios";
  
  export default {
    data() {
      return {
        dialog: false,
        headers: [
          {
            text: "Doador",
            align: "center",
            value: "donor.name",
          },

          { text: "Ações", value: "actions", sortable: false,  align: "center" },
        ],

        donorSearch: {
          name:"",
          rgct:"",
          mother_name:""
        },
  
        displayError: {
          display: false,
          message: [],
        },
        
        totalDonors: " ",
        page: 1,
        totalUsers: 0,
        numberOfPages: 0,
        passengers: [],
        loading: true,
        options: {},
        sortBy: "name",
        filterUnity: "",
        filtro: {},
        donor: [],
        usuarios: [],
        unidades: [],
        hospital: [],
        message: "",
        snackbar: false,
        loadingBTN: false,
        exibirMensagem: false,
        messageError: "",
        id: "",
        name: "",
        cpf: "",
        email: "",
        birth: "",
        mother_name: "",
      };
    },
  
    components: {
    },

    watch: {
      sortBy: {
        handler() {
  
        }
      },
      options: {
        handler() {
          this.indoDataTable();
        },
      },
      deep: true,
    },
  
    methods: {
  
      indoDataTable() {
        this.loading = true;
          axios.get(`/serology/list`).then((response) => {
            this.loading = false;
            this.donor = response.data;
          });
      },
  
      search() {
        var filtro = (this.donorSearch)
          axios.post("/donor/filter", filtro).then((response) => {
            this.loading = true;
            this.donor = response.data.data; 
            this.loading = false;
        });
      },
      
      openCreateDoador() {
        this.dialog = true;
      },

      clear(){
      this.donorSearch.name = null,
      this.donorSearch.rgct = null,
      this.donorSearch.mother_name = null
      this.indoDataTable();
      this.$emit("eventname", '');
      },

    },
  
    mounted() {
      this.indoDataTable();
    },
    
  };
  </script>
  
  <style scoped>
  .header-table {
    display: flex;
    margin-bottom: 20px;
  }

  .d-flex{
    padding: 0 12px;
  }
  
  .space {
    width: 5px;
  }

  .space-personalized{
  padding: 9px;
  }

  .spacediferent {
  width: 7px;
}
  .v-btn:not(.v-btn--round).v-size--default {
    height: 40px !important;
  }
  
  .textBtn {
    font-family: "Poppins";
    color: #ffffff;
  }
  
  .create-user-dialog {
    padding: 20px;
  }
  
  .card {
    padding: 35px;
  }
  
  .banner {
    background: #b3ffab; /* fallback for old browsers */
    background: -webkit-linear-gradient(
      to right,
      #27ae60,
      #1abc9c
    ); /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(
      to right,
      #ee106d,
      #e46987
    ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  
    border-radius: 8px;
  
    margin-bottom: 20px;
  
    display: flex;
    align-items: center;
  }
  
  .icon{

  }

  .banner > h4 {
    color: #ffffff;
    padding: 10px;
  }
  
  .cardFooter {
    padding: 0 !important;
  }
  </style>
  